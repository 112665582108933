<template>
  <div v-show="!isHidden" :class="CSSClasses" :style="computedStyle" v-loading="loading" @click="onClick"
      :data-type="source.type"
      :data-source-id="source.entityId"
      :data-attribute="name || null"
      :data-alias="editorAlias || null">
    {{value}}
    <el-tooltip v-if="tooltip" class="item" effect="dark" :content="tooltip">
      <i class="el-icon-question"></i>
    </el-tooltip>
    <slot></slot>
  </div>
</template>

<script>
import mixin from '../mixins'
import VisibleMixin from '../visible_properties_mixin'
import RegistryCard from '@/components/RegistryCard/index.vue'

import ActionExecutor from '@/core/infrastructure/service/ActionExecutor.ts'

import { eventBus } from '@/eventBus'
import FilterBuilder, { EComponentTypes } from '../utils'
import Dashboard from '@/components/Dashboard'
import { isNumeric, isEmpty } from '@/helpers'
import refreshComponentsMixin from '@/components/InterfaceEditor/components/refreshComponentsMixin'

export default {
  name: 'a-label',
  components: {
    Dashboard,
    RegistryCard
  },
  inject: {
    getViewer: {
      default: () => {
        return {}
      }
    },
    isEditor: {
      default: () => false
    },
    forceUpdateSettingsPanel: {
      default: () => () => {}
    },

    /* Зависимости ActionExecutor */
    getCard: {
      default: () => {}
    },
    getParentDashboard: {
      default: () => {}
    },
    getParentContext: {
      default: () => {}
    },
    addMainTab: {
      default: () => {}
    },
    updateTab: {
      default: () => {}
    },
    tabs: {
      default: () => {}
    },
    activeTab: {
      default: () => {}
    },
    closeTab: {
      default: () => {}
    },
    openedCards: {
      default: () => {}
    },
    cancelChanges: {
      default: () => {}
    },
    openRegistryCard: {
      default: () => {}
    },
    openDashboardCard: {
      default: () => {}
    },
    openTabModalWindow: {
      default: () => {}
    },
    getContainersStore: {
      default: () => () => {}
    },
    getInterfaceWrapper: {
      default: () => () => {}
    }
  },
  mixins: [mixin, VisibleMixin, refreshComponentsMixin],
  props: {
    name: {
      type: String,
      description: 'attribute',
      options: {
        removeSpaces: true
      }
    },
    editorAlias: {
      type: String,
      description: 'alias'
    },
    text: {
      type: String,
      description: 'text',
      default: 'Текст'
    },
    source: {
      type: Object,
      editor: 'Source',
      default: () => {
        return {
          type: null,
          entityId: null,
          valueField: {}
        }
      }
    },
    format: {
      type: String,
      description: 'form_date',
      options: {
        tooltip: {
          content: 'xref_format_date',
          show: true
        }
      }
    },
    action: {
      type: Object,
      editor: 'ButtonAction',
      default: () => {
        return {
          type: 'execute_plugin',
          dashboard: {
            id: null,
            isFullscreen: false,
            window_width: 25,
            window_title: null,
            breadcrumbByButton: true
          },
          command: {
            id: null,
            success_text: null,
            failure_text: null
          },
          card: {
            registryId: null,
            type: null,
            fieldId: null,
            constantRecordId: null,
            cardId: null,
            frameGuid: null,
            containerAlias: null,
            isWindow: false,
            windowWidth: 25,
            windowTitle: null,
            defaults: []
          },
          reports: {
            id: null,
            guid: null,
            name: null,
            formatType: null,
            viewType: null,
            registryId: null,
            assocFieldId: null,
            openSavedVersion: false
          },
          url: {
            url: null,
            openType: null
          }
        }
      }
    },
    pluginName: {
      type: String,
      description: 'plugin',
      editor: 'Plugin'
    },
    align: {
      type: String,
      description: 'align'
    },
    margin: {
      type: String,
      description: 'margin',
      default: '5px 10px'
    },
    block: {
      type: Boolean,
      description: 'full_line',
      default: true
    },
    size: {
      type: String,
      description: 'size_font'
    },
    weight: {
      type: Boolean,
      description: 'weight'
    },
    tooltip: {
      description: 'tooltip',
      type: String
    },
    filters: {
      type: Array,
      editor: 'Filters',
      options: {
        showXrefOption: true,
        showEqualsTypes: true
      }
    }
  },
  data () {
    return {
      loading: false,
      value: null,
      filterDebounce: undefined
    }
  },
  computed: {
    variableValue () {
      if (this.source.type === 'variable' && this.source.valueField.name) {
        let value = null
        let isGetModel = this.getModel() && typeof this.getModel()[this.source.valueField.name] !== 'undefined'
        let isGetRawData = this.getRawData() && typeof this.getRawData()[this.source.valueField.name] !== 'undefined'

        // проверить является ли переменная (source.valueField.name) ссылкой
        let isXref = null
        if (isGetRawData) {
          try {
            isXref = Array.isArray(JSON.parse(this.getRawData()[this.source.valueField.name]))
          } catch (e) {
            isXref = Array.isArray(this.getRawData()[this.source.valueField.name])
          }
        }
        if (isGetModel && !isXref) {
          value = this.getModel()[this.source.valueField.name]
        } else if (isGetRawData) {
          value = this.getRawData()[this.source.valueField.name]
        }
        const isNumber = isNumeric(value)
        const saveValue = value

        try {
          value = JSON.parse(value)
        } catch (e) {
        }

        // JSON.parse() окгругляет большие значения  № 66231706600005424133-> № 66231706600005400000
        const format = (this.format || '').trim()

        if (isNumber && !Array.isArray(value) && !format) {
          return saveValue
        }

        if (value && Array.isArray(value)) {
          value = value.map(item => item.name).join(',')
        } else if (value && typeof value === 'object' && value?.address) {
          value = value.address
        }
        if (value && format && format.length > 0) {
          value = this.$moment(value).format(format)
        }
        if (value == null || value === undefined || value === '') {
          return this.text
        } else {
          return value
        }
      }
      return null
    },
    dataFilters () {
      const builder = new FilterBuilder(
        this.filters,
        this.getModel(),
        this.$store,
        EComponentTypes.label
      )

      const filters = builder.buildAsApiQl()

      if (filters.length > 0) {
        return {
          where: {
            and: [...filters]
          }
        }
      }

      return {}

      // let filters = {
      //   'where': {
      //     'and': []
      //   }
      // }
      // let isset = false
      // if (this.filters) {
      //   this.filters.forEach((item) => {
      //     let type = item.isXref ? 'equals_any' : 'eq'
      //     let object = {}
      //     if (!item.type || item.type === 'field') {
      //       if (this.getModel()[item.attribute] && item.alias) {
      //         object[type] = {}
      //         object[type][item.alias] = this.getModel()[item.attribute]
      //         filters.where.and.push(object)
      //         isset = true
      //         // filters.push(`${item.alias}=${this.getModel()[item.attribute]}`)
      //       }
      //     } else if (item.type === 'constant' && item.alias) {
      //       object[type] = {}
      //       object[type][item.alias] = item.attribute
      //       // filters.push(`${item.alias}=${item.attribute}`)
      //       filters.where.and.push(object)
      //       isset = true
      //     } else if (item.type === 'current_user') {
      //       object[type] = {}
      //       object[type][item.alias] = this.$store.getters['Authorization/userId']
      //       // filters.push(`${item.alias}=${this.$store.getters['Authorization/userId']}`)
      //       filters.where.and.push(object)
      //       isset = true
      //     }
      //   })
      // }
      // return isset ? filters : null
    },
    queryValue () {
      if (this.source.type === 'query' && !this.isEditor()) {
        let returnObj = {}
        this.source.queryParameters.forEach(el => {
          let value
          if (el.type === 'field') value = this.getModel()[el.attribute]
          if (el.type === 'constant') value = el.attribute
          if (el.type === 'current_user') value = this.$store.getters['Authorization/userId']

          if (isEmpty(value)) value = null
          let formattedObj = { [el.alias]: value }
          returnObj = Object.assign(returnObj, formattedObj)
        })
        return returnObj
      }
      return null
    },
    computedStyle () {
      let css = this.CSS
      if (this.align) {
        css += ';text-align:' + this.align
      }
      if (this.margin) {
        css += ';margin:' + this.margin
      }
      if (!this.block) {
        css += ';display: inline-block'
      }
      if (this.size) {
        css += ';font-size: ' + this.size
      }
      if (this.weight) {
        css += ';font-weight: bold'
      }

      return css
    }
  },
  watch: {
    dataFilters () {
      if (this.filterDebounce) {
        clearTimeout(this.filterDebounce)
      }
      this.filterDebounce = setTimeout(() => {
        this.loadData()
      }, 300)
    },
    text (val) {
      this.value = val
    },
    variableValue () {
      this.loadData()
    },
    editorAlias () {
      this.forceUpdateSettingsPanel()
    },
    queryValue: {
      handler: function () {
        this.loadData()
      },
      deep: true
    }
  },
  /* beforeDestroy () {
    eventBus.$off('registry-card-saved')
  }, */
  async mounted () {
    this.value = this.text
    if (!this.isEditor() && /user.attr_[0-9]+_/i.test(this.value)) {
      this.value = await this.$store.getters['Authorization/userAttributeData'](this.value.match(/attr_[0-9]+_/gi)[0])
    }
    await this.loadData()

    /* if (this.action.card.type === 'add' || this.action.card.type === 'update') {
      eventBus.$on('registry-card-saved', () => { this.refreshComponents(this.action.card.componentsGuid) })
    } */
  },
  methods: {
    async loadData () {
      if (this.source.type === 'extended_object' && this.source.entityId && this.source.valueField.id && !this.isEditor()) {
        this.loading = true
        // let data = await this.$http.get(`${this.$config.api}/datawarehouseservice/extended_object/${this.source.entityId}?${this.dataFilters.join('&')}`)
        let data = await this.$http.post(`${this.$config.api}/datawarehouseservice/extended_object/${this.source.entityId}`, this.dataFilters, {
          hideNotification: true
        })
          .finally(() => {
            this.loading = false
          })
        data = data.data[0]
        if (data) {
          this.value = data[this.source.valueField.name] || this.text
        } else {
          this.value = this.text
        }
      } else if (this.source.type === 'variable' && this.source.valueField.name) {
        this.value = this.variableValue
      } else if (this.source.type === 'query' && this.source.entityId && !this.isEditor()) {
        this.queryParams = {
          query_parameters: this.queryValue
        }
        let data = await this.$http.post(`${this.$config.api}/datawarehouseservice/query/${this.source.entityId}`, this.queryParams, {
          hideNotification: true
        })
        data = data.data[0]
        let finalValue = this.text
        if (!data) {
          finalValue = ''
        }
        for (const alias in data) {
          finalValue = finalValue.replaceAll(`{{${alias}}}`, data[alias])
        }
        this.value = finalValue
        this.$set(this, 'value', finalValue)
      }
      // Если указан атрибут - прокидывать значения ( для фильтров )
      if (this.name) {
        this.$emit('input', this.value)
      }
    },
    async onClick (event) {
      ActionExecutor.execute(this, { readonly: this._isReadonly, pluginName: this.pluginName, action: this.action, event: event })
    }
  }
}
</script>

<style scoped>

</style>
